import React from 'react';
import classNames from 'classnames';
import { Point } from 'js/components/common/Point/Point';
import { getPoints } from 'js/utils/point';

export type Props = {
  itemId: number;
  linkUrl: string;
  imageUrl: string;
  itemName: string;
  point1?: number;
  point2: number;
  affiliatePoint?: number;
  affiliatePointCondition?: string;
};

export const ResearchBannerItemPresenter: React.FC<Props> = ({
  linkUrl,
  imageUrl,
  itemName,
  point1,
  point2,
  affiliatePoint,
  affiliatePointCondition,
}) => {
  const answerPoint = getPoints(point1, point2).join(
    String.fromCharCode(0xff5e),
  );

  return (
    <li className={classNames('p_item-list__item', 'p_item-unit')}>
      <a
        className="p_item-unit__link c_reset c_blocklink c_hover-pop"
        href={linkUrl}
      >
        <figure className="p_item-unit__image-wrapper">
          <img
            className="p_item-unit__image"
            src={imageUrl}
            alt=""
            width="200"
            height="200"
            loading="lazy"
          />
        </figure>
        <div className="p_item-unit__name">{itemName}</div>
        <div className="p_item-unit__condition">
          アンケート
          {affiliatePointCondition && (
            <>
              &nbsp;
              <span className="p_item-unit__delimiter">/</span>
              &nbsp;
              {affiliatePointCondition}
            </>
          )}
        </div>
        <div className="p_item-unit__point">
          <div className="p_research-item">
            <span className="c_point c_x_large">{answerPoint}</span>
            {affiliatePoint && (
              <>
                &nbsp;
                <span className="p_item-unit__delimiter">/</span>
                &nbsp;
                <Point
                  className="p_item-unit__after-point"
                  point={affiliatePoint}
                  unit="amount"
                  size="x-large"
                />
              </>
            )}
          </div>
        </div>
      </a>
    </li>
  );
};
