import { useRequest } from 'js/hooks/useRequest';

type ResearchItem = {
  supplierId?: string;
  supplierName?: string;
  itemId?: number;
  publishedAt?: number;
  title?: string;
  url?: string;
  point1?: number;
  pointCondition1?: string;
  duration1?: number;
  givingDate1?: string;
  point2?: number;
  pointCondition2?: string;
  duration2?: number;
  status?: number;
  givingDate?: string;
  givingDate2?: string;
  affiliatePoint?: number;
  affiliatePointCondition?: string;
  affiliateGivingDate?: string;
  bannerUrl?: string;
  attentionUrl?: string;
  type?: string;
  enqueteAppUrl?: string;
};

export interface ResearchItemsResponse {
  isSuccess?: boolean;
  code?: number;
  userStatus?: number;
  items?: ResearchItem[];
}

export const useResearchItems = () => {
  const { data } = useRequest<ResearchItemsResponse>(
    '/research/api/orsay/items/',
  );
  return { researchItems: data };
};
