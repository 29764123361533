import React from 'react';
import classNames from 'classnames';
import {
  Props as ResearchBannerItemProps,
  ResearchBannerItem,
} from 'js/components/specific/top/Research/ResearchBannerItem';
import { ColumnType } from 'js/types/gridList';

export type Props = {
  list: ResearchBannerItemProps[];
  column?: Omit<ColumnType, 'auto'>;
};

export const ResearchBannerListPresenter: React.FC<Props> = ({
  list,
  column,
}) => (
  <ul
    className={classNames(
      'c_contents-group',
      'p_item-list__list',
      { [`column-${String(column)}`]: column },
      'u_bg-white',
    )}
  >
    {list.map((item) => (
      <ResearchBannerItem {...item} key={item.itemId} />
    ))}
  </ul>
);
