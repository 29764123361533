import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import useWindowSize from 'js/hooks/useWindowSize';
import { AffiliateSimpleList } from 'js/components/common/Affiliate/AffiliateSimpleList';
import { TeibanServiceItem } from 'js/components/specific/top/TeibanService/type';
import { ColumnType } from 'js/types/gridList';

export type Props = {
  teibanService: TeibanServiceItem[];
  column?: ColumnType;
  defaultCurrent?: string;
};

export const TeibanServicePresenter: React.FC<Props> = ({
  teibanService,
  column,
  defaultCurrent = '',
}) => {
  const [current, setCurrent] = useState<string>(defaultCurrent);
  const [affiliateHeight, setAffiliateHeight] = useState<number>(0);
  const [totalHeight, setTotalHeight] = useState<number>(0);
  const affiliateRefs = useRef<Record<string, HTMLDivElement>>({});
  const windowSize = useWindowSize();

  const handleClick = (code: string) => {
    const isClosing = code === current;
    setCurrent(isClosing ? '' : code);
  };
  const scrollToContent = () => {
    if (!current) {
      return;
    }
    const contentElement = affiliateRefs.current[current];
    const contentRect = contentElement.getBoundingClientRect();
    const bottomMenu = document.querySelector('.bottom-menu');
    const bottomMenuHeight = bottomMenu?.getBoundingClientRect().height || 0;
    const isContentVisible =
      contentRect.bottom <= windowSize.height - bottomMenuHeight;
    // 展開するコンテンツが可視領域に収まるか判断してスクロールさせている
    if (!isContentVisible) {
      const scrollElement = document.querySelector(
        '.teiban-service-scroll__content',
      );
      scrollElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      /*
       * アニメーション前にスクロール位置を取得するため、
       * 非表示要素にコンテンツと同じ高さをセットしている。
       * 以下で長々と書いているが、DOM参照して値をセットしているだけ。
       */
      const contentHeight = affiliateRefs.current[current]?.offsetHeight || 0;
      const group = document.querySelector('.teiban-service-group');
      const groupHeight = group?.getBoundingClientRect().height || 0;
      const groupStyle = window.getComputedStyle(group);
      const groupMarginBottom = parseFloat(
        groupStyle.getPropertyValue('margin-bottom'),
      );
      const bottomMenu = document.querySelector('.bottom-menu');
      const bottomMenuHeight = bottomMenu?.getBoundingClientRect().height || 0;
      // 親要素側でアニメーションさせるため子要素の高さをセット
      setAffiliateHeight(contentHeight);
      setTotalHeight(
        groupHeight +
          groupMarginBottom +
          contentHeight +
          // ボトムメニューはコンテンツの高さではないが隠れる分スクロール位置をずらす
          bottomMenuHeight,
      );
    });
  }, [current, windowSize]);

  // https://github.com/voyagegroup/ecnavi/pull/17243/files#r1418282950
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => scrollToContent(), [current, totalHeight]);

  return (
    <>
      <div className="teiban-service-scroll">
        <div
          className="teiban-service-scroll__content"
          style={{
            // アニメーション前にスクロール位置を取得するための非表示要素
            height: `${totalHeight}px`,
          }}
        />
      </div>
      <ul className="teiban-service-group">
        {teibanService.map((group) => (
          <li
            className={classNames('teiban-service-group__item', {
              'is-current': group.code === current,
            })}
            key={group.code}
          >
            <button
              className="teiban-service-group__button"
              type="button"
              onClick={() => handleClick(group.code)}
            >
              <img
                className={classNames(
                  'teiban-service-group__image',
                  `icon-${group.code}`,
                )}
                src={`/top/img/teiban_service/icon_${group.code}.svg`}
                alt={group.name}
                title={group.name}
              />
            </button>
          </li>
        ))}
      </ul>
      <ul
        className={classNames('teiban-service-affiliate', {
          'is-open': current,
        })}
        style={{ height: `${affiliateHeight}px` }}
      >
        {teibanService.map((group) => (
          <li
            className={classNames('teiban-service-affiliate__item', {
              'is-open': group.code === current,
            })}
            key={group.code}
            style={
              group.code === current ? { height: `${affiliateHeight}px` } : {}
            }
          >
            <div
              className="teiban-service-affiliate__wrapper"
              ref={(element) => (affiliateRefs.current[group.code] = element)}
            >
              <AffiliateSimpleList list={group.list} column={column} />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
