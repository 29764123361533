import React from 'react';
import {
  ResearchBannerItemPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Research/ResearchBannerItem/presenter';

export type Props = PresenterProps;

export const ResearchBannerItem: React.FC<Props> = ({
  itemId,
  linkUrl,
  imageUrl,
  itemName,
  point1,
  point2,
  affiliatePoint,
  affiliatePointCondition,
}) => (
  <ResearchBannerItemPresenter
    itemId={itemId}
    linkUrl={linkUrl}
    imageUrl={imageUrl}
    itemName={itemName}
    point1={point1}
    point2={point2}
    affiliatePoint={affiliatePoint}
    affiliatePointCondition={affiliatePointCondition}
  />
);
