import React from 'react';
import {
  ResearchBannerListPresenter,
  Props as PresenterProps,
} from 'js/components/specific/top/Research/ResearchBannerList/presenter';

export type Props = PresenterProps;

export const ResearchBannerList: React.FC<Props> = ({ list, column }) => (
  <ResearchBannerListPresenter list={list} column={column} />
);
