import { useEffect, useState } from 'react';
import { Props as ResearchBannerItemProps } from 'js/components/specific/top/Research/ResearchBannerItem';
import { useResearchItems } from 'js/hooks/pages/useResearchItems';

export const useResearchItemsData = () => {
  const MAX_DISPLAY_NUM = 4;
  const [researchData, setResearchData] = useState<
    ResearchBannerItemProps[] | undefined
  >();
  const { researchItems } = useResearchItems();
  useEffect(() => {
    if (researchItems) {
      if (researchItems.isSuccess) {
        setResearchData(
          researchItems.items
            .filter((item) => item.point2 > 0)
            .map((item) => ({
              itemId: item.itemId,
              linkUrl: item.url,
              imageUrl: item.bannerUrl ?? '/top/img/icon_research_80px.svg',
              itemName: item.title,
              point1: item.point1,
              point2: item.point2,
              affiliatePoint: item.affiliatePoint,
              affiliatePointCondition: item.affiliatePointCondition,
            }))
            .slice(0, MAX_DISPLAY_NUM),
        );
      } else {
        setResearchData(undefined);
      }
    }
  }, [researchItems]);
  return { researchData };
};
