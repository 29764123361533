// 3つの数字毎にカンマ区切りで表示する
export const pointFormat = (point: number) => {
  return point.toString().replace(/([\d]+?)(?=(?:\d{3})+$)/g, (t) => {
    return t + ',';
  });
};

export const getPoints = (point1?: number, point2?: number) => {
  const points = [];
  if (point1) {
    points.push(pointFormat(point1));
  }
  if (point2) {
    points.push(pointFormat(point2));
  }
  return points;
};
